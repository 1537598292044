import React, { useCallback, useState, useEffect } from 'react';
import { Modal, theme } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ContainerOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import SITELINKS from 'constants/sitelinks';
import { useBuildPackage } from 'util/Hooks';
import ViewPackageButton from 'components/viewPackageButton';
import {
  loadedSelector,
  itemsSelector,
  pageableSelector,
} from 'redux/packageMassiveList/selectors';
import { actions } from 'redux/packageMassiveList/slice';
import useMassiveViewPackages from 'redux/packageMassiveList';
import SizeIndicator from 'components/sizeIndicator';
import EditRecordButton from 'components/editRecordButton';
import { useOnTransitStatusOptions } from 'hooks/useOptions';
import { selectGlobals } from 'redux/globals/selectors';

const useStyles = createUseStyles((_theme) => {
  return {
    antModalContent: {
      padding: 0,
    },
    icon: {
      color: _theme.colorPrimary,
      fontSize: 18,
    },
  };
});

const useColumns = () => {
  const externalCodeColumn = {
    title: 'Externo',
    dataIndex: 'externalCode',
    width: 130,
    render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
  };

  const destinationPersonNameColumn = {
    title: 'Nombre destinatario',
    dataIndex: 'destinationPersonName',
  };

  const destinationAddressColumn = {
    title: 'Destino',
    dataIndex: 'destinationAddress',
  };

  const deliveryTermColumn = {
    title: 'Plazo',
    dataIndex: 'deliveryTermDescription',
    width: 130,
  };

  const arrivedAtPaqueryPointDateColumn = {
    title: 'Arribó a PaqueryPoint',
    dataIndex: 'arrivedAtPaqueryPointDate',
    align: 'center',
  };

  const statusColumn = {
    title: 'Estado',
    dataIndex: 'statusDescription',
  };

  const sizeColumn = {
    title: 'Tamaño',
    dataIndex: 'packageSizeDescription',
    align: 'center',
    render: (text) => <SizeIndicator text={text[0]} />,
    sorter: true,
    sortDirections: ['descend'],
  };

  const editColumn = {
    align: 'center',
    render: (_, record) => (
      <EditRecordButton
        record={record}
        link={`${SITELINKS.packages.list}/id`}
      />
    ),
  };

  const viewPackageColumn = {
    align: 'center',
    render: (_, record) => <ViewPackageButton packet={record} />,
  };

  const dataColumns = [
    externalCodeColumn,
    destinationPersonNameColumn,
    destinationAddressColumn,
    deliveryTermColumn,
    sizeColumn,
    arrivedAtPaqueryPointDateColumn,
    statusColumn,
  ];
  return {
    dataColumns,
    columnsLargeDevice: [...dataColumns, viewPackageColumn, editColumn],
    columnsSmallDevice: [externalCodeColumn, viewPackageColumn, editColumn],
    columnsMediumDevice: [
      externalCodeColumn,
      statusColumn,
      viewPackageColumn,
      editColumn,
    ],
  };
};

const PackagesList = ({ code }) => {
  const { token } = theme.useToken();
  const [visible, setVisible] = useState(false);
  useMassiveViewPackages({ initialize: visible, code });

  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const classes = useStyles({ theme: token });
  const {
    dataColumns,
    columnsLargeDevice,
    columnsSmallDevice,
    columnsMediumDevice,
  } = useColumns();

  const items = useSelector(itemsSelector);
  const pageable = useSelector(pageableSelector);
  const loaded = useSelector(loadedSelector);
  const filteredStatus = useOnTransitStatusOptions();
  const filteredPackages = useBuildPackage(items);

  useEffect(() => {
    if (visible) {
      dispatch(actions.updateCode(code));
    }
  }, [visible]);

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
  };
  const selectors = [
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Tipo de envio',
      list: globals.packages.type,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: filteredStatus,
    },
  ];
  return (
    <>
      <ContainerOutlined
        onClick={() => setVisible(true)}
        className={classes.icon}
      />
      <Modal
        title="Listado de paquetes"
        width="80vw"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        closable={false}
        className={classes.antModalContent}
        open={visible}
      >
        <PaqueryTable
          loading={!loaded}
          header={{
            title: code,
            selectors,
            searcher,
            rangePicker: {
              onDateSelection: updateDate,
            },
            refresh: () => dispatch(actions.refreshPage()),
          }}
          usePackageRowColors
          onChangePaginate={updatePaginate}
          dataSource={filteredPackages}
          paginate={pageable || DEFAULT_PAGINATE}
          dataColumns={dataColumns}
          colsForSmallDevice={columnsSmallDevice}
          colsForMediumDevice={columnsMediumDevice}
          colsForLargeDevice={columnsLargeDevice}
        />
      </Modal>
    </>
  );
};

PackagesList.propTypes = {
  code: PropTypes.number.isRequired,
};

export default PackagesList;
