/* eslint-disable import/no-named-as-default-member */
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import rest, { getData } from 'util/Api';
import { actions } from './slice';
import { selectPackages } from './selectors';

const URL = `${API.packages.get}`;

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      search,
      status,
      type,
      term,
      date,
      storeId,
    } = yield select(selectPackages);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      packageType: type,
      deliveryTerm: term,
      storeId: storeId || '',
      status,
      search,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(getData, `${URL}?${searchParams.toString()}`);
    const items = packages.content;
    const total = packages.totalElements;
    yield put({
      type: actions.fetchPackagesSuccess.type,
      payload: {
        items,
        total,
      },
    });
  } catch (error) {
    // eslint-disable-next-line
    console.log('Fetch saga search by code error: ', error);
    yield put({ type: actions.fetchPackagesFailure.type });
  }
}

export const fetchPackagesForCsv = async (
  dispatch,
  search,
  marketplaceId,
  storeId,
  packageType,
  deliveryTerm,
  status,
  date,
) => {
  try {
    const searchParams = new URLSearchParams({
      search,
      marketplaceId,
      storeId: storeId || '',
      packageType,
      deliveryTerm,
      status,
      from: date.from,
      to: date.to,
    });
    const response = await rest.get(
      `${API.reports.packages}?${searchParams.toString()}`,
      {
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const fetchPackage = async (externalCode, dispatch) => {
  try {
    if (externalCode === null || externalCode === '') {
      const error = new Error('El codigo externo no puede ir vacío.');
      return error;
    }
    dispatch(actions.fetchOnePacket());
    const packet = await rest.get(
      `${API.packages.getByExternalCode}${externalCode}`,
    );
    dispatch(actions.fetchOnePacketSuccess());
    return packet.data.content[0];
  } catch (error) {
    dispatch(actions.fetchOnePacketFailure());
    return error;
  }
};

function* updateBasefilters() {
  const { status, date } = yield select(selectPackages);
  if (status === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetchPackages.type,
  });
}

function* addPacketSagas({ payload }) {
  const { items } = yield select(selectPackages);
  const index = items.findIndex((packet) => packet.id === payload.id);
  if (index !== -1 && items[index].status === payload.status) {
    return;
  }
  yield call(updateBasefilters);
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updatePageable,
        actions.updateSearch,
        actions.updatePackageType,
        actions.updateDeliveryTerm,
        actions.updateStatus,
        actions.refreshPage,
        actions.resetFilters,
        actions.updatePacket,
        actions.updateDate,
        actions.updateStore,
      ],
      updateBasefilters,
    ),
    takeLatest(actions.updatePacket, addPacketSagas),
    takeLatest([actions.fetchPackages], fetchSaga),
  ]);
}
