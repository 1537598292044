import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

export default function DeleteRecordButton({ onClick }: any) {
  return (
    <CloseCircleOutlined
      style={{ color: 'red', fontSize: 18 }}
      onClick={onClick}
    />
  );
}
