import { getData } from 'util/Api';
import { MARKETPLACE_ROLES } from 'constants/roles';
import API from 'constants/api';
import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import { message } from 'antd';
import { authProfile, authStoreSelector } from 'redux/auth/selectors';
import { actions } from './slice';
import { storeItemsSelector } from './selectors';

const URL = API.stores.get;

function* initialSaga() {
  const items = yield select(storeItemsSelector);
  if (!items || items.length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      page: 0,
      size: 10000,
      take: 10000,
      search: '',
    });
    let stores = [];
    const profile = yield select(authProfile);
    const userLoggedStore = yield select(authStoreSelector);
    if (userLoggedStore) {
      stores.push(userLoggedStore);
    }
    if (MARKETPLACE_ROLES.includes(profile.userRoleID)) {
      const storesFetched = yield call(
        getData,
        `${URL}?${searchParams.toString()}`,
      );
      stores = stores.concat(storesFetched.content);
    }
    yield put({ type: actions.success.type, payload: stores });
  } catch (error) {
    yield put({ type: actions.failure.type });
    message.error(error.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchSaga),
  ]);
}
