import { createSelector } from 'reselect';
import {
  ALL_ROLES_FILTERING,
  ALL_STATUS_FILTERING,
} from 'constants/defaultValues';
import { name, initialState } from './slice';

const selectUsers = (state) => state[name] || initialState;
export const usersItemsSelector = (state) => Object.values(state[name].items);
export const usersLoadedSelector = (state) => state[name].loaded;
export const usersSearchSelector = (state) => state[name].search;
export const usersRoleSelector = (state) => state[name].role;
export const usersStatusSelector = (state) => state[name].status;
export const usersPageableSelector = (state) => state[name].pageable;

const makeSelectUsers = () =>
  createSelector(selectUsers, (substate) => substate);

export const userItemsStatusFilter = createSelector(
  usersItemsSelector,
  usersStatusSelector,
  (items, status) => {
    if (!items) return [];
    if (status === ALL_STATUS_FILTERING) {
      return items;
    }
    return items.filter((item) => item.statusID === status);
  },
);

export const userItemsRoleFilter = createSelector(
  userItemsStatusFilter,
  usersRoleSelector,
  (filteredItems, role) => {
    if (role === ALL_ROLES_FILTERING) {
      return filteredItems;
    }
    return filteredItems.filter((item) => item.userRoleID === role);
  },
);

export const usersFilteredPagedItems = createSelector(
  userItemsRoleFilter,
  usersPageableSelector,
  usersSearchSelector,
  (filteredItems, { pageNumber, pageSize }, search) => {
    let resultItems = filteredItems;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase().trim();
        const itemFullName = `${item.name.toUpperCase()} ${item.lastName.toUpperCase()}`;
        return itemFullName.includes(searchIgnoreCase);
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makeSelectUsers;
