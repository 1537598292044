import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'formik-antd';
import { theme } from 'antd';
import { TableDateFormat } from 'constants/dateFormats';
import useStyles from './styles';

const DatePickerField = ({
  label,
  labelStyle,
  containerStyle,
  fieldName,
  placeholder,
  disabled,
  dateFormat,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  return (
    <div style={{ textAlign: 'left', ...containerStyle }}>
      <span style={labelStyle}>{label}</span>
      <div className={classes.topSelectEffect}>
        <DatePicker
          name={fieldName}
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
      <div style={{ height: 30 }} />
    </div>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  labelStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
};

DatePickerField.defaultProps = {
  disabled: false,
  placeholder: 'Seleccione fecha',
  dateFormat: TableDateFormat,
  labelStyle: undefined,
  containerStyle: undefined,
};

export default DatePickerField;
