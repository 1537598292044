/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import dayjs from 'dayjs';
import usePackages from 'redux/packages';
import { useSelector, useDispatch } from 'react-redux';
import { authProfile, authMarketplaceSelector } from 'redux/auth/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import {
  packagesLoadedSelector,
  packagesSearchSelector,
  packagesStatusSelector,
  packagesDateSelector,
  packagesItemsSelector,
  packagesPageableSelector,
  packagesTypeSelector,
  packagesTermSelector,
  packagesStoreSelector,
} from 'redux/packages/selectors';
import { fetchPackagesForCsv } from 'redux/packages/saga';
import { actions } from 'redux/packages/slice';
import { actions as storeActions } from 'redux/store/slice';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { STORE_ROLES, MARKETPLACE_ROLES } from 'constants/roles';
import { PACKAGE } from 'constants/packages';
import SITELINKS from 'constants/sitelinks';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import { notification } from 'antd';
import PaqueryTable from '@paquery-team/lib-table';
import { useBuildPackage } from 'util/Hooks';
import EditRecordButton from 'components/editRecordButton';
import ViewPackageButton from 'components/viewPackageButton';
import DownloadLabelButton from 'components/downloadLabelButton';
import SizeIndicator from 'components/sizeIndicator';
import {
  useDirectStatusOptions,
  useSendStatusOptions,
  useStoreOptions,
} from 'hooks/useOptions';
import useStore from 'redux/store';
import { selectGlobals } from 'redux/globals/selectors';

const downloadLabelColumn = {
  render: (_, record) => (
    <DownloadLabelButton
      packageId={record.key}
      externalCode={record.externalCode}
    />
  ),
};

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationPersonNameColumn = {
  title: 'Nombre destinatario',
  dataIndex: 'destinationPersonName',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTermDescription',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
};

const sizeColumn = {
  title: 'Tamaño',
  dataIndex: 'packageSizeDescription',
  align: 'center',
  render: (text) => <SizeIndicator text={text[0]} />,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packet={record} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationPersonNameColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  sizeColumn,
  arrivedAtPaqueryPointDateColumn,
  statusColumn,
];

const columnsLargeDevice = [
  downloadLabelColumn,
  ...dataColumns,
  viewPackageColumn,
  editColumn,
];

const columnsMediumDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  statusColumn,
  viewPackageColumn,
  editColumn,
];

const columnsSmallDevice = [
  downloadLabelColumn,
  externalCodeColumn,
  viewPackageColumn,
  editColumn,
];

const PackageList = () => {
  usePackages();
  useStore();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const search = useSelector(packagesSearchSelector);
  const status = useSelector(packagesStatusSelector);
  const deliveryTerm = useSelector(packagesTermSelector);
  const date = useSelector(packagesDateSelector);
  const packageType = useSelector(packagesTypeSelector);
  const loaded = useSelector(packagesLoadedSelector);
  const items = useSelector(packagesItemsSelector);
  const pageable = useSelector(packagesPageableSelector);
  const marketplace = useSelector(authMarketplaceSelector);
  const stores = useSelector(storeItemsSelector);
  const storesOptions = useStoreOptions(stores);
  const storeFilter = useSelector(packagesStoreSelector);
  const profile = useSelector(authProfile);
  const storeStatuses = useSendStatusOptions();
  const storeWithdrawalStatuses = useDirectStatusOptions();
  const filteredStatus =
    packageType === PACKAGE.TYPES.DIRECT
      ? storeWithdrawalStatuses
      : storeStatuses;
  const filteredPackages = useBuildPackage(items);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (MARKETPLACE_ROLES.some((mkpRole) => mkpRole === profile.userRoleID)) {
      dispatch(storeActions.fetch());
    }
  }, [profile]);

  const generateLabels = async () => {
    try {
      const packagesIds = filteredPackages.map((packet) => packet.id);
      const response = await rest.post(
        `${API.packages.getLabels}`,
        packagesIds,
        {
          responseType: 'blob',
        },
      );
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Etiquetas generadas correctamente',
          description: 'Las etiquetas se han generado correctamente',
        });
      }
      const filename = 'Etiquetas.pdf';
      JsDownload(response.data, filename);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const { id: marketplaceId } = marketplace;
      const storeId = STORE_ROLES.some(
        (storeRole) => storeRole === profile.userRoleID,
      )
        ? profile.store.id
        : storeFilter;
      const csv = await fetchPackagesForCsv(
        dispatch,
        search,
        marketplaceId,
        storeId,
        packageType,
        deliveryTerm,
        status,
        date,
      );
      const marketplaceName = marketplace.name
        ? marketplace.name
        : 'Marketplace';
      const filename = `Paquetes-${marketplaceName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(csv, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );

  const packageTypeSearchCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectDeliveryTermCallback = useCallback(
    (newDeliveryTerm) => {
      dispatch(actions.updateDeliveryTerm(newDeliveryTerm));
    },
    [dispatch],
  );
  const selectStoreCallback = useCallback(
    (storeSelected) => {
      dispatch(actions.updateStore(storeSelected));
    },
    [dispatch],
  );
  const selectors = STORE_ROLES.some(
    (storeRole) => storeRole === profile.userRoleID,
  )
    ? [
        {
          onChange: packageTypeSearchCallback,
          list: globals.packages.type,
          placeholder: 'Tipo de envio',
        },
        {
          onChange: selectCallback,
          placeholder: 'Estado',
          list: filteredStatus,
        },
        {
          onChange: selectDeliveryTermCallback,
          placeholder: 'Plazo',
          list: globals.deliveryTerm,
        },
      ]
    : [
        {
          onChange: selectStoreCallback,
          placeholder: 'Tienda',
          list: storesOptions,
        },
        {
          onChange: packageTypeSearchCallback,
          list: globals.packages.type,
          placeholder: 'Tipo de envio',
        },
        {
          onChange: selectCallback,
          placeholder: 'Estado',
          list: filteredStatus,
        },
        {
          onChange: selectDeliveryTermCallback,
          placeholder: 'Plazo',
          list: globals.deliveryTerm,
        },
      ];
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'En transito',
        selectors,
        searcher,
        rangePicker: {
          onDateSelection: updateDate,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        onExportCsv: { callback: handleExportCsv, disabled },
        onGenerateLabels: generateLabels,
        refresh: () => dispatch(actions.refreshPage()),
      }}
      usePackageRowColors
      onChangePaginate={updatePaginate}
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default PackageList;
