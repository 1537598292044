import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'antd';
import { Input, FormItem } from 'formik-antd';
import useStyles from './styles';

const { TextArea } = Input;

const TextAreaField = ({
  label,
  labelStyle,
  containerStyle,
  fieldName,
  onBlur,
  prefix,
  disabled = false,
  placeholder,
  maxLength = 150,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  return (
    <div style={{ textAlign: 'left', ...containerStyle }}>
      <span style={labelStyle}>{label}</span>
      <FormItem name={fieldName}>
        <div className={classes.topSelectEffect}>
          <TextArea
            name={fieldName}
            prefix={prefix}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e.target.value);
              }
            }}
            style={{
              width: '100%',
            }}
            placeholder={placeholder || label}
            disabled={disabled}
            showCount
            maxLength={maxLength}
          />
        </div>
      </FormItem>
    </div>
  );
};

TextAreaField.propTypes = {
  maxLength: PropTypes.number,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  onBlur: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
};

TextAreaField.defaultProps = {
  containerStyle: {},
  placeholder: null,
  labelStyle: null,
  disabled: false,
  onBlur: null,
  maxLength: 150,
  prefix: null,
};

export default TextAreaField;
