import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: {},
  loaded: false,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  search: '',
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      action.payload.forEach((store) => {
        state.items[store.id] = store;
      });
      state.loaded = true;
    },
    failure: (state) => {
      state.loaded = false;
    },
    loading: (state) => {
      state.loaded = false;
    },
    loaded: (state) => {
      state.loaded = true;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
      state.pageable.pageNumber = initialState.pageable.pageNumber;
    },
    updatePageable: (state, action) => {
      state.pageable.pageSize = action.payload.pageSize;
      state.pageable.pageNumber = action.payload.pageNumber;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    refreshPage: (state) => {
      state.items = {};
    },
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = storeSlice;
