import { createSelector } from 'reselect';
import { name, initialState } from './slice';

const selectAuth = (state) => state[name] || initialState;

export const authTokenSelector = (state) => state[name].sessionToken;

export const authIsLoginSelector = (state) => state[name].isLoginIn;

export const authProfile = (state) => (state[name] || initialState).profile;

export const authProfileRoleId = (state) =>
  (state[name] || initialState).profile.userRoleID;

export const authGeocodeParametersSelector = (state) =>
  state[name].profile.geocode;

export const authMarketplaceSelector = (state) =>
  state[name].profile.marketplace;

export const authStoreSelector = (state) => state[name].profile.store;

const makeSelectAuth = () => createSelector(selectAuth, (substate) => substate);

export default makeSelectAuth;
