import {
  ALL_ROLES_FILTERING,
  ALL_STATUS_FILTERING,
} from 'constants/defaultValues';
import API from 'constants/api';
import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import rest from 'util/Api';
import { actions } from './slice';
import { usersRoleSelector, usersStatusSelector } from './selectors';

const URL = `${API.user.getAll}`;

function* fetchUserSaga() {
  try {
    const role = yield select(usersRoleSelector);
    const status = yield select(usersStatusSelector);
    const searchParams = new URLSearchParams({
      search: '',
      take: 10000,
      page: 0,
      desc: true,
      status,
      userType: '',
      userRol: role,
    });
    const request = yield call(rest.get, `${URL}?${searchParams.toString()}`);
    const payload = {
      role: role === '' ? ALL_ROLES_FILTERING : role,
      status: status === '' ? ALL_STATUS_FILTERING : status,
      items: request.data.data,
    };
    yield put({ type: actions.fetchSuccess.type, payload });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.fetchFailure.type });
  }
}

function* initialSaga() {
  yield put({ type: actions.fetch.type });
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchUserSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
    // takeEvery(actions.updateStatus, updateStatusSaga),
  ]);
}
