import React, { useCallback, useState, useEffect } from 'react';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { STORE_ROLES, MARKETPLACE_ROLES } from 'constants/roles';
import SITELINKS from 'constants/sitelinks';
import { TableDateTimeFormat, ExportDateFormat } from 'constants/dateFormats';
import { notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import usePackageHistory from 'redux/packagesHistory/index';
import globalSelector from 'redux/globals/selectors';
import {
  packagesHistoryItemsSelector,
  packagesHistoryPaginationSelector,
  packagesHistoryLoadedSelector,
  packagesHistoryDateSelector,
  packagesHistorySearchSelector,
  packagesHistoryStatusSelector,
  packagesHistoryTypeSelector,
  packagesHistoryTermSelector,
  packagesHistoryStoreSelector,
} from 'redux/packagesHistory/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import { actions } from 'redux/packagesHistory/slice';
import { actions as storeActions } from 'redux/store/slice';
import { fetchPackagesForCsv } from 'redux/packagesHistory/saga';
import ModalButton from 'components/modalButton';
import ViewPackageButton from 'components/viewPackageButton';
import dayjs from 'dayjs';
import PaqueryTable from '@paquery-team/lib-table';
import { authProfile, authMarketplaceSelector } from 'redux/auth/selectors';
import JsDownload from 'js-file-download';
import EditRecordButton from 'components/editRecordButton';
import { useFinalStatusOptions, useStoreOptions } from 'hooks/useOptions';
import useStore from 'redux/store';
import { PACKAGE } from 'constants/packages';

const statusStyle = (status) => {
  let bgColor;
  switch (status) {
    case PACKAGE.STATUSES.DELIVERED:
      bgColor = 'green';
      break;
    case PACKAGE.STATUSES.CANCELLED:
      bgColor = 'red';
      break;
    case PACKAGE.STATUSES.DAMAGED:
      bgColor = 'orange';
      break;
    case PACKAGE.STATUSES.RETURNED:
      bgColor = 'gold';
      break;
    default:
      break;
  }
  return {
    background: bgColor,
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
};

const statusColumn = {
  title: 'Estado',
  render: (_, record) => (
    <span style={statusStyle(record.status)}>{record.statusDescription}</span>
  ),
};

const driverAvatar = (record) => (
  <ModalButton
    name={record.driverName}
    image={record.driverImage}
    icon={<UserOutlined />}
  />
);

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const driverColumn = {
  title: 'PaQuer',
  align: 'center',
  render: (_, record) => driverAvatar(record),
};

const driverNameColumn = {
  title: 'Conductor',
  dataIndex: 'driverName',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton record={record} link={`${SITELINKS.packages.list}/id`} />
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packet={record} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  driverNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  driverColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  statusColumn,
  viewPackageColumn,
  editColumn,
];

const columnsSmallDevice = [externalCodeColumn, viewPackageColumn, editColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  viewPackageColumn,
  editColumn,
];

const PackageHistory = () => {
  usePackageHistory();
  useStore();
  const dispatch = useDispatch();
  const globals = useSelector(globalSelector(), shallowEqual);
  const loaded = useSelector(packagesHistoryLoadedSelector);
  const date = useSelector(packagesHistoryDateSelector);
  const packageType = useSelector(packagesHistoryTypeSelector);
  const search = useSelector(packagesHistorySearchSelector);
  const status = useSelector(packagesHistoryStatusSelector);
  const storeFilter = useSelector(packagesHistoryStoreSelector);
  const deliveryTerm = useSelector(packagesHistoryTermSelector);
  const marketplace = useSelector(authMarketplaceSelector);
  const stores = useSelector(storeItemsSelector);
  const storesOptions = useStoreOptions(stores);
  const profile = useSelector(authProfile);
  const items = useSelector(packagesHistoryItemsSelector);
  const pageable = useSelector(packagesHistoryPaginationSelector);
  const historyStatus = useFinalStatusOptions();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (MARKETPLACE_ROLES.some((mkpRole) => mkpRole === profile.userRoleID)) {
      dispatch(storeActions.fetch());
    }
  }, [profile]);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const { id: marketplaceId } = marketplace;
      const storeId = STORE_ROLES.some(
        (storeRole) => storeRole === profile.userRoleID,
      )
        ? profile.store.id
        : storeFilter;
      const csv = await fetchPackagesForCsv(
        dispatch,
        search,
        marketplaceId,
        storeId,
        packageType,
        deliveryTerm,
        status,
        date,
      );
      const marketplaceName = marketplace.name
        ? marketplace.name
        : 'Marketplace';
      const filename = `Paquetes-Historial-${marketplaceName}-${dayjs()
        .tz()
        .format(ExportDateFormat)}.xls`;
      JsDownload(csv, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código o destinatario',
    allowEmptySearch: true,
  };
  const selectDeliveryTermCallback = useCallback(
    (newDeliveryTerm) => {
      dispatch(actions.updateDeliveryTerm(newDeliveryTerm));
    },
    [dispatch],
  );
  const selectStoreCallback = useCallback(
    (storeSelected) => {
      dispatch(actions.updateStore(storeSelected));
    },
    [dispatch],
  );
  const selectors = STORE_ROLES.some(
    (storeRole) => storeRole === profile.userRoleID,
  )
    ? [
        {
          onChange: selectPackageTypeCallback,
          list: globals.packages.type,
          placeholder: 'Tipo de envio',
        },
        {
          list: historyStatus,
          onChange: selectStatusCallback,
          placeholder: 'Estado',
        },
        {
          onChange: selectDeliveryTermCallback,
          placeholder: 'Plazo',
          list: globals.deliveryTerm,
        },
      ]
    : [
        {
          onChange: selectStoreCallback,
          placeholder: 'Tienda',
          list: storesOptions,
        },
        {
          onChange: selectPackageTypeCallback,
          list: globals.packages.type,
          placeholder: 'Tipo de envio',
        },
        {
          onChange: selectStatusCallback,
          placeholder: 'Estado',
          list: historyStatus,
        },
        {
          onChange: selectDeliveryTermCallback,
          placeholder: 'Plazo',
          list: globals.deliveryTerm,
        },
      ];

  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      content: packet.caption,
      driverImage: packet.shippingScheduleDestination.driver
        ? packet.shippingScheduleDestination.driver.avatar
        : null,
      driverName: packet.shippingScheduleDestination.driver
        ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName}`
        : null,
      deliveryDate: packet.deliveryDate
        ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      signatureImage: packet.signatureImage,
      signatureName: packet.shippingScheduleDestination.name,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'Histórico',
        searcher,
        selectors,
        rangePicker: {
          onDateSelection: dateSelectionCallback,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      usePackageRowColors
      onChangePaginate={(values) => dispatch(actions.updatePagination(values))}
      dataSource={filteredPackages}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default PackageHistory;
