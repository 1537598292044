import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spin, notification, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/userView/slice';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import InputField from 'components/Forms/input';
import UploadField from 'components/Forms/upload';
import SelectField from 'components/Forms/select';
import useStyles from 'components/Forms/styles';
import { STORE_ROLES, MARKETPLACE_ROLES } from 'constants/roles';
import SITELINKS from 'constants/sitelinks';
import { useHistory } from 'react-router-dom';
import { authProfileRoleId } from 'redux/auth/selectors';
import ChangePassword from 'components/changePassword';
import Card from 'components/card';

const User = ({
  disabledInputs,
  loaded,
  avatar,
  validationSchema,
  onSubmit,
  currentCity,
  cities,
  roles,
  setCurrentCountry,
  initialValues,
  stores,
  countries,
  isProfile,
  isUserModify,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const formik = useRef();
  const optionGenerator = (arrayData) =>
    arrayData.map((data) => ({ label: data.name, value: data.id }));
  const [currentRole, setCurrentRole] = useState(false);
  useEffect(() => {
    formik.current.setFieldValue('cityID', currentCity);
  }, [currentCity]);
  useEffect(() => {
    setCurrentRole(initialValues.userRoleID);
  }, [initialValues]);
  useEffect(() => {}, []);
  const statusList = [
    { name: 'Activo', id: 1 },
    { name: 'Dado de baja', id: 6 },
  ];
  const optionStores = stores.map((store) => ({
    label: `Codigo: ${store.storeCode} - ${store.name}`,
    value: store.id,
  }));
  const optionsCountries = optionGenerator(countries);
  const optionsCities = optionGenerator(cities);
  const optionsStatus = optionGenerator(statusList);
  const optionsRoles = roles.map((role) => ({
    label: role.name,
    value: role.value,
  }));
  const profileRoleId = useSelector(authProfileRoleId);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleChange = (role) => {
    setCurrentRole(role);
    if (STORE_ROLES.includes(role)) {
      if (stores.length === 0) {
        notification.info({
          message:
            'Es necesario que cargues al menos una tienda para asignarla a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(SITELINKS.stores.add);
        }, 2000);
      }
    }
    formik.current.setFieldValue('store', null);
  };

  const handleImageChange = (image) => dispatch(actions.setAvatar(image));

  const showStoreSelector = () => {
    // Si el usuario logeado, es de tipo STORE, mostrar el selector de tiendas siempre.
    if (STORE_ROLES.includes(profileRoleId)) {
      return true;
    }
    // Si el usuario logeado, es de tipo MARKETPLACE, mostrar el selector de tiendas solo si el Rol actual seleccionado es de tipo STORE.
    if (
      MARKETPLACE_ROLES.includes(profileRoleId) &&
      STORE_ROLES.includes(currentRole)
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    await onSubmit(values);
    setLoadingSubmit(false);
  };

  return (
    <Spin spinning={!loaded}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formik}
      >
        {({ values }) => (
          <Form>
            <Row type="flex" justify="space-between">
              <Col xs={24} md={14}>
                <Card
                  title="Información básica"
                  style={{ margin: '8px', height: '96.5%' }}
                >
                  <Row className={classes.cardContent}>
                    <Col xs={24} lg={8}>
                      <UploadField
                        image={avatar}
                        onChangeImage={handleImageChange}
                      />
                    </Col>
                    <Col xs={24} lg={16}>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <InputField label="ID" fieldName="id" disabled />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Nombre" fieldName="name" />
                        </Col>
                        <Col xs={24} md={12}>
                          <InputField label="Apellido" fieldName="lastName" />
                        </Col>
                        <Col span={24}>
                          <SelectField
                            label="Rol"
                            fieldName="userRoleID"
                            onChange={(role) => handleChange(role)}
                            options={optionsRoles}
                          />
                          {showStoreSelector() && (
                            <Col span={24}>
                              <SelectField
                                label="Tienda"
                                fieldName="ownerID"
                                options={optionStores}
                              />
                            </Col>
                          )}
                        </Col>
                        {(isProfile || isUserModify) && (
                          <Col span={24}>
                            <ChangePassword email={values.email} />
                          </Col>
                        )}
                        {isUserModify && (
                          <Col span={24}>
                            <SelectField
                              label="Estado"
                              fieldName="statusID"
                              options={optionsStatus}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={10}>
                <Card
                  title="Información de contacto"
                  style={{ margin: '8px', height: '96.5%' }}
                >
                  <Row>
                    <Col span={24} className={classes.cardContent}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24}>
                          <InputField
                            label="Móvil"
                            fieldName="phone"
                            type="number"
                          />
                        </Col>
                        <Col xs={24}>
                          <InputField
                            disabled={disabledInputs.email}
                            label="Email"
                            fieldName="email"
                          />
                        </Col>
                        <Col xs={24}>
                          <SelectField
                            label="País"
                            fieldName="countryID"
                            onChange={(country) => setCurrentCountry(country)}
                            options={optionsCountries}
                          />
                        </Col>
                        <Col xs={24}>
                          <SelectField
                            label="Ciudad"
                            fieldName="cityID"
                            options={optionsCities}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  loading={loadingSubmit}
                  type="primary"
                  htmlType="submit"
                  className={classes.saveButton}
                  size="large"
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

User.propTypes = {
  loaded: PropTypes.bool.isRequired,
  avatar: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isProfile: PropTypes.bool,
  isUserModify: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentCity: PropTypes.number,
  cities: PropTypes.arrayOf(PropTypes.object),
  setCurrentCountry: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    lastname: PropTypes.string,
    pwd: PropTypes.string,
    phone: PropTypes.number,
    confirmpwd: PropTypes.string,
    cityID: PropTypes.number,
    countryID: PropTypes.number,
    userRoleID: PropTypes.number,
    store: PropTypes.arrayOf(PropTypes.object),
  }),
  disabledInputs: PropTypes.shape({
    email: PropTypes.bool,
  }),
};

User.defaultProps = {
  isProfile: false,
  isUserModify: false,
  currentCity: 1,
  cities: [],
  setCurrentCountry: 1,
  initialValues: {
    id: null,
    name: '',
    lastname: '',
    pwd: '',
    confirmpwd: '',
    phone: null,
    confirmpassword: '',
    cityID: null,
    countryID: 1,
    store: '',
  },
  disabledInputs: {
    email: false,
  },
};

export default User;
