import React, { useEffect, useCallback } from 'react';
import rest from 'util/Api';
import store from 'app/store';
import { storeLoadedSelector, storeItemsFiltered } from 'redux/store/selectors';
import { actions } from 'redux/store/slice';
import { useSelector, useDispatch } from 'react-redux';
import useStore from 'redux/store';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import API from 'constants/api';
import DeleteModal from 'components/deleteModal';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';

const deleteAction = (id, name) => {
  const deleteRequest = async () => {
    let response;
    try {
      response = await rest.del(`${API.stores.create}/${id}`);
      if (rest.isSuccessResponse(response)) {
        store.dispatch(actions.refreshPage());
        store.dispatch(actions.fetch());
      }
    } catch (error) {
      // Nothing to do
    }
    return response;
  };
  DeleteModal({
    title: `¿Estas seguro de que deseas eliminar la tienda ${name}?`,
    onConfirmation: deleteRequest,
  });
};
const storeNumberColumn = {
  title: 'Código de tienda',
  dataIndex: 'key',
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const addressColumn = {
  title: 'Dirección',
  dataIndex: 'address',
};

const contactColumn = {
  title: 'Responsable',
  dataIndex: 'contactName',
};

const activeColumn = {
  title: 'Activo',
  dataIndex: 'active',
};

const dataColumns = [
  storeNumberColumn,
  nameColumn,
  contactColumn,
  addressColumn,
  activeColumn,
];

const editColumn = {
  align: 'center',
  render: (_, record) => <EditRecordButton record={record} link="/stores" />,
};

const deleteColumn = {
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() => deleteAction(record.key, `${record.name}`)}
    />
  ),
};

const columnsLargeDevice = [...dataColumns, editColumn, deleteColumn];

const columnsSmallDevice = [nameColumn, editColumn, deleteColumn];

const columnsMediumDevice = [
  nameColumn,
  storeNumberColumn,
  editColumn,
  deleteColumn,
];

const PackageList = () => {
  useStore();
  const dispatch = useDispatch();
  const loaded = useSelector(storeLoadedSelector);
  const { items, pageable } = useSelector(storeItemsFiltered);

  useEffect(() => {
    dispatch(actions.fetch());
  }, [dispatch]);

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };
  let filteredStores = [];
  if (loaded && items) {
    filteredStores = items.map((storeItem) => ({
      key: storeItem.id,
      name: storeItem.name,
      contactName: storeItem.contactName,
      storeNumber: storeItem.storeCode,
      address: storeItem.address,
      active: storeItem.active ? 'Si' : 'No',
    }));
  }
  return (
    <>
      <PaqueryTable
        loading={!loaded}
        header={{
          title: 'Tiendas',
          searcher,
          primaryButton: <TableHeader.AddButton url="/stores/add" />,
          refresh: () => dispatch(actions.fetch()),
        }}
        onChangePaginate={updatePaginate}
        dataSource={filteredStores}
        paginate={pageable || DEFAULT_PAGINATE}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

export default PackageList;
