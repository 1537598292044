import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  storeId: '',
  search: '',
  status: '',
  type: '',
  term: '',
  date: {
    from: '',
    to: '',
  },
  sort: '',
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    fetchPackages: (state) => {
      state.loaded = false;
    },
    fetchPackagesSuccess: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    fetchPackagesFailure: (state) => {
      state.loaded = true;
    },
    updatePacket: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    updatePackageType: (state, action) => {
      state.pageable.pageNumber = 0;
      state.type = action.payload;
    },
    updateDeliveryTerm: (state, action) => {
      state.pageable.pageNumber = 0;
      state.term = action.payload;
    },
    updateStore: (state, action) => {
      state.pageable.pageNumber = 0;
      state.storeId = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSortPackages: (state, action) => {
      state.loaded = false;
      state.sort = action.payload;
    },
    updatePackagePaquer: (state, action) => {
      state.items.find(
        (item) => item.id === action.payload.packageId,
      ).shippingScheduleDestination.driver = action.payload.selectedPaquer;
    },
    resetFetchedDates: (state) => {
      state.fetchedDates = {};
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.date = initialState.date;
      state.pageable = initialState.pageable;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = packagesSlice;
