import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'antd';
import { FormItem, AutoComplete } from 'formik-antd';
import { useDebounce } from 'util/Hooks';
import useStyles from './styles';

const WAIT_INTERVAL = 400;

const AutoCompleteField = ({
  label,
  labelStyle,
  fieldName,
  placeholder,
  startValue,
  disabled,
  onSearch,
  onSelect,
  options,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const [search, setSearch] = useState(startValue);
  const mounted = useRef(null);
  const debouncedSearch = useDebounce(search, WAIT_INTERVAL);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      onSearch(debouncedSearch);
    }
  }, [debouncedSearch, onSearch]);
  return (
    <>
      <FormItem name={fieldName}>
        <span style={{ fontSize: '0.7rem', ...labelStyle }}>{label}</span>
        <div className={classes.topSelectEffect}>
          <AutoComplete
            defaultValue={startValue}
            onChange={(data) => setSearch(data)}
            onSelect={onSelect}
            options={options}
            name={fieldName}
            dropdownMatchSelectWidth={500}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder || label}
          />
        </div>
      </FormItem>
    </>
  );
};

AutoCompleteField.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.shape({}),
  fieldName: PropTypes.string.isRequired,
  startValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

AutoCompleteField.defaultProps = {
  placeholder: undefined,
  labelStyle: undefined,
  disabled: false,
  startValue: null,
  options: [],
};

export default AutoCompleteField;
