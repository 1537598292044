import { useSelector, shallowEqual } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { name as key, reducer } from './slice';
import saga from './saga';
import makeSelectStoreModifyNormal from './selectors';

export default function useStoreNormalPackages() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useSelector(makeSelectStoreModifyNormal(), shallowEqual);
}
