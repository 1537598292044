import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { notification, Tabs } from 'antd';
import rest from 'util/Api';
import * as Yup from 'yup';
import { authProfile } from 'redux/auth/selectors';
import { actions } from 'redux/store/slice';
import { PHONE_REGEX } from 'constants/defaultValues';
import { OTHER_NOTIFIER_OPTION } from 'constants/options';
import API from 'constants/api';
import { storeItemsSelector, storeLoadedSelector } from 'redux/store/selectors';
import useStore from 'redux/store';
import Store from '../store';
import StorePackages from './components/packages';
import StoreHistoryPackages from './components/history';

const { TabPane } = Tabs;

const defaultValues = {
  detail: '',
  address: '',
  contactName: '',
  email: '',
  name: '',
  phone: '',
  id: '',
  username: '',
  hasIntegration: false,
  notifierType: null,
};

const validationWithoutIntegration = Yup.object().shape({
  name: Yup.string().required('Especifique el nombre de la tienda'),
  storeCode: Yup.string().required('Especifique el código de la tienda'),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Formato de telefono invalido.')
    .typeError('Especifique el teléfono de la tienda')
    .required('Especifique el teléfono de la tienda'),
});

const fullValidation = Yup.object()
  .shape({
    username: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().required(
        'Indique el nombre de usuario de la integración',
      ),
      otherwise: Yup.string().nullable(),
    }),
    notifierType: Yup.number().when('hasIntegration', {
      is: true,
      then: Yup.number()
        .typeError('Indique el tipo de integración')
        .required('Indique el tipo de integración'),
      otherwise: Yup.number().nullable(),
    }),
    password: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().required(
        'Especifique la contraseña de la integración',
      ),
      otherwise: Yup.string().nullable(),
    }),
    confirmpassword: Yup.string().when('hasIntegration', {
      is: true,
      then: Yup.string().test(
        'password-match',
        'Las contraseñas deben coincidir',
        function callback(value) {
          return this.parent.password === value;
        },
      ),
      otherwise: Yup.string().nullable(),
    }),
  })
  .concat(validationWithoutIntegration);

const StoreModify = () => {
  useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const { storeId } = useParams();
  const fullItems = useSelector(storeItemsSelector);
  const profile = useSelector(authProfile);
  const loaded = useSelector(storeLoadedSelector);
  const [successFetch, setSuccessFetch] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [disabledIntegration, setDisabledIntegration] = useState(false);

  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);

  useEffect(() => {
    const getStore = () => {
      if (loaded && fullItems) {
        try {
          const store = fullItems.find(
            (item) => item.id === parseInt(storeId, 10),
          );
          setStoreData({
            active: store.active,
            detail: store.detail,
            address: store.address,
            contactName: store.contactName,
            email: store.email,
            name: store.name,
            phone: store.phone,
            id: store.id,
            storeCode: store.storeCode,
            username: store.credentials?.username,
            hasIntegration: !!store.credentials,
            notifierType:
              store.notifierTypeValue || OTHER_NOTIFIER_OPTION.value,
          });
          if (store?.credentials?.username) setDisabledIntegration(true);
          else
            setStoreData((state) => ({
              ...state,
              password: '',
              confirmpassword: '',
            }));
        } catch (error) {
          notification.error({
            message: 'Ha ocurrido un error',
            description: 'No se pudo obtener la tienda indicada.',
          });
          setTimeout(() => {
            history.goBack();
          }, 1500);
        } finally {
          setSuccessFetch(true);
        }
      }
    };
    if (!successFetch) {
      getStore();
    }
  }, [storeId, successFetch, loaded, fullItems, history]);

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    let credentials;
    if (values.hasIntegration) {
      credentials = {};
      const { password, username, notifierType } = values;
      if (password !== '') {
        credentials.password = password;
      }
      credentials.username = username;
      credentials.notifier =
        notifierType !== OTHER_NOTIFIER_OPTION.value ? notifierType : null;
    }
    const payload = {
      id: parseInt(storeId, 10),
      name: values.name,
      detail: values.detail,
      address: values.address
        ? values.address
        : profile.marketplace.dispatchAddress,
      phone: values.phone,
      contactName: values.contactName,
      email: values.email,
      active: values.active,
      ownerId: profile.marketplace.id,
      ownerType: profile.ownerType,
      storeCode: values.storeCode,
      credentials,
    };
    try {
      const response = await rest.put(
        `${API.stores.create}/${storeId}`,
        payload,
      );
      if (rest.isSuccessResponse(response)) {
        dispatch(actions.refreshPage());
        notification.success({
          message: 'Actualización de Tienda',
          description: `La Tienda ${values.name} ha sido actualizada correctamente.`,
        });
        if (response?.data?.data?.credentials) {
          notification.success({
            message: 'Credenciales actualizadas',
            description: 'Las contraseñas de integración han sido actualizadas',
          });
        }
        setTimeout(() => {
          history.push('/stores');
        }, 1000);
      }
    } catch (error) {
      dispatch(actions.loaded());
    }
  };
  return (
    <Tabs defaultActiveKey="Tienda" size="large" centered>
      <TabPane tab="Tienda" key="profile">
        <Store
          validationSchema={
            disabledIntegration ? validationWithoutIntegration : fullValidation
          }
          initialValues={storeData || defaultValues}
          onSubmit={handleSubmit}
          disabledIntegration={disabledIntegration}
        />
      </TabPane>
      <TabPane tab="Paquetes" key="packages">
        <StorePackages />
      </TabPane>
      <TabPane tab="Historial" key="packagesHistory">
        <StoreHistoryPackages />
      </TabPane>
    </Tabs>
  );
};

export default StoreModify;
