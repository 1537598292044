import React, { useState, useEffect } from 'react';
import {
  BankOutlined,
  ProfileOutlined,
  UserOutlined,
  ContainerOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { MARKETPLACE_ROLES, STORE_ROLES } from 'constants/roles';
import SITELINKS from 'constants/sitelinks';

const MARKETPLACE_USER_MENU = [
  {
    key: 'packagesBySearchCode',
    title: 'Buscar por código',
    icon: <SearchOutlined />,
    link: SITELINKS.packages.bySearchCode,
  },
  {
    key: 'packages',
    title: 'Paquetes',
    icon: <ContainerOutlined />,
    subMenuItems: [
      {
        key: 'packages',
        title: 'En transito',
        link: SITELINKS.packages.list,
      },
      {
        key: 'packagesHistory',
        title: 'Histórico',
        link: SITELINKS.packages.history,
      },
      {
        key: 'packageWithoutZones',
        title: 'Sin zona',
        link: SITELINKS.packages.withoutZone,
      },
      {
        key: 'massivePackageList',
        title: 'Alta masiva',
        link: SITELINKS.storePackages.massive,
      },
      {
        key: 'sendOnePackage',
        title: 'Alta individual',
        link: SITELINKS.packages.send,
      },
    ],
  },
  {
    key: 'administration',
    title: 'Administración',
    icon: <UserOutlined />,
    subMenuItems: [
      {
        key: 'users',
        title: 'Usuarios',
        icon: <UserOutlined />,
        link: SITELINKS.users.list,
      },
      {
        key: 'stores',
        title: 'Tiendas',
        icon: <BankOutlined />,
        link: SITELINKS.stores.list,
      },
    ],
  },
  {
    key: 'profile',
    title: 'Perfil',
    icon: <ProfileOutlined />,
    link: SITELINKS.profiles,
  },
  {
    key: 'support',
    title: 'Soporte',
    link: SITELINKS.support.index,
    icon: <QuestionCircleOutlined />,
  },
];

const STORE_USER_MENU = [
  {
    key: 'packagesBySearchCode',
    title: 'Buscar por código',
    icon: <SearchOutlined />,
    link: SITELINKS.packages.bySearchCode,
  },
  {
    key: 'packages',
    title: 'Paquetes',
    icon: <ContainerOutlined />,
    subMenuItems: [
      {
        key: 'packages',
        title: 'En transito',
        link: SITELINKS.packages.list,
      },
      {
        key: 'packagesHistory',
        title: 'Histórico',
        link: SITELINKS.packages.history,
      },
      {
        key: 'massivePackageList',
        title: 'Alta masiva',
        link: SITELINKS.storePackages.massive,
      },
      {
        key: 'sendOnePackage',
        title: 'Alta individual',
        link: SITELINKS.packages.send,
      },
    ],
  },
  {
    key: 'administration',
    title: 'Administración',
    icon: <UserOutlined />,
    subMenuItems: [
      {
        key: 'users',
        title: 'Usuarios',
        icon: <UserOutlined />,
        link: SITELINKS.users.list,
      },
    ],
  },
  {
    key: 'profile',
    title: 'Perfil',
    icon: <ProfileOutlined />,
    link: SITELINKS.profiles,
  },
  {
    key: 'support',
    title: 'Soporte',
    link: SITELINKS.support.index,
    icon: <QuestionCircleOutlined />,
  },
];

export default function useMenu() {
  const [menu, setMenu] = useState([]);
  const profile = useSelector(authProfile);
  useEffect(() => {
    if (profile) {
      if (
        MARKETPLACE_ROLES.some(
          (marketRole) => marketRole === profile.userRoleID,
        )
      ) {
        setMenu(MARKETPLACE_USER_MENU);
      }
      if (STORE_ROLES.some((storeRole) => storeRole === profile.userRoleID)) {
        setMenu(STORE_USER_MENU);
      }
    }
  }, [profile]);
  return menu;
}
