import { getCoordsFromPolygonGeoJson } from 'constants/operations';
import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const zoneSelector = (state) => state[name] || initialState;

const zoneItemsObjectSelector = (state) => state[name].items;

export const zoneItemsSelector = createSelector(
  zoneItemsObjectSelector,
  (items) => {
    if (!items) return [];
    return Object.values(items);
  },
);

export const zoneSearchSelector = (state) => state[name].search;

export const zoneLoadedSelector = (state) => state[name].loaded;

export const zonePageableSelector = (state) => state[name].pageable;

export const zonesPolygonsSelector = createSelector(
  zoneItemsSelector,
  (zones) => {
    if (zones.length === 0) return [];
    return zones
      .filter((zone) => zone.geoJson)
      .map((zone) => {
        try {
          return {
            id: zone.id,
            detail: zone.name,
            path: getCoordsFromPolygonGeoJson(zone.geoJson),
          };
        } catch (error) {
          console.log('error mapping polygons: ', error.message);
          return {};
        }
      });
  },
);

const makeZoneSelector = () =>
  createSelector(zoneSelector, (substate) => substate);

export const zoneFilteredItemsSelector = createSelector(
  zoneItemsSelector,
  zonePageableSelector,
  zoneSearchSelector,
  (items, { pageSize, pageNumber }, search) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const storeNameIgnoreCase = item.name.toUpperCase();
        return storeNameIgnoreCase.includes(searchIgnoreCase);
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makeZoneSelector;
