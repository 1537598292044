import {
  call,
  put,
  takeEvery,
  select,
  all,
  takeLatest,
} from 'redux-saga/effects';
import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { actions } from './slice';
import { selectPackages } from './selectors';

const URL = API.packages.getNormalByStoreId;

function* fetchSaga() {
  try {
    const {
      storeId,
      pageable: { pageNumber, pageSize },
      search,
      status,
      type,
      date,
    } = yield select(selectPackages);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      desc: true,
      search,
      packageType: type,
      from: date.from,
      to: date.to,
      status,
    });
    const packages = yield call(
      getData,
      `${URL(storeId)}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.success.type,
      payload: {
        items: packages.content,
        total: packages.totalElements,
      },
    });
  } catch (error) {
    // eslint-disable-next-line
    console.log('fetch saga error: ', error);
    yield put({ type: actions.failure.type });
  }
}

function* updateBasefilters() {
  const { status, date, type } = yield select(selectPackages);
  if (status === null || type === null || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.setDate,
        actions.setPackageType,
        actions.setStatus,
        actions.setSearch,
        actions.setPageable,
        actions.refresh,
      ],
      updateBasefilters,
    ),
    takeEvery(actions.fetch, fetchSaga),
  ]);
}
