import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import rest, { getData } from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import { userViewAvatar, userViewLoaded } from 'redux/userView/selectors';
import useStore from 'redux/store';
import { selectGlobals } from 'redux/globals/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import { actions as storeActions } from 'redux/store/slice';
import { authProfile } from 'redux/auth/selectors';
import useUserview from 'redux/userView/index';
import { actions as authActions } from 'redux/auth/slice';
import { actions } from 'redux/userView/slice';
import { notification } from 'antd';
import User from 'routes/app/user';
import { IMAGE_REGEX, PHONE_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Formato de telefono invalido.')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
});

const defaultValues = {
  name: '',
  lastName: '',
  phone: null,
  email: '',
};

const UserProfile = () => {
  useUserview();
  useStore();
  const globals = useSelector(selectGlobals);
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const loaded = useSelector(userViewLoaded);
  const avatar = useSelector(userViewAvatar);
  const stores = useSelector(storeItemsSelector);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();

  useEffect(() => {
    dispatch(storeActions.initial());
    return () => {
      dispatch(actions.resetValues());
    };
  }, [dispatch]);

  useEffect(() => {
    const getCities = async () => {
      dispatch(actions.setLoaded(false));
      const citiesResponse = await getData(
        `${API.globals.cities}/${currentCountry}`,
      );
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(undefined);
      }
      dispatch(actions.setLoaded(true));
    };
    if (currentCountry) {
      getCities();
    }
  }, [currentCountry, dispatch]);

  useEffect(() => {
    dispatch(actions.resetValues());
    dispatch(actions.setLoaded(false));
    const getCitiesAndLoadProfile = async () => {
      const citiesResponse = await getData(
        `${API.globals.cities}/${profile.countryID}`,
      );
      setCities(citiesResponse);
      setInitialValues(profile);
      dispatch(actions.setLoaded(true));
    };
    dispatch(actions.setAvatar({ data: profile.avatar }));
    getCitiesAndLoadProfile();
  }, [profile, dispatch]);

  const handleSubmit = async (values) => {
    const payload = {
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      CityID: values.cityID,
      CountryID: values.countryID,
      Email: values.email,
      id: values.id,
      LastName: values.lastName,
      LogisticOperatorID: values.logisticOperatorID || null,
      MarketplaceId: profile.marketplace.id,
      Mobile: values.phone,
      Name: values.name,
      UserRoleID: values.userRoleID,
    };
    try {
      const response = await rest.post(API.user.update, payload);
      if (response.status === 400) {
        throw new Error(
          response.data.message || 'Error al actualizar el perfil',
        );
      } else {
        notification.success({
          message: 'Actualización de perfil',
          description: 'Tu perfil ha sido actualizado correctamente',
        });
        dispatch(authActions.reloadProfile());
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <User
      loaded={loaded}
      avatar={avatar}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      stores={stores}
      countries={globals.countries}
      cities={cities}
      roles={globals.roles}
      currentCity={currentCity}
      currentCountry={currentCountry}
      setCurrentCountry={setCurrentCountry}
      isProfile
    />
  );
};

export default UserProfile;
