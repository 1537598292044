import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { PREFIX_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import { TableDateTimeFormat } from 'constants/dateFormats';
import dayjs from 'dayjs';
import { selectCountries, selectGlobals } from 'redux/globals/selectors';
import { useSelector } from 'react-redux';

export default function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);
  return debouncedValue;
};

const getFullAddress = ({
  street,
  houseNumber,
  district,
  postalCode,
  city,
  country,
}) =>
  `${street}${houseNumber ? ` ${houseNumber}` : ''}${
    district ? `, ${district}` : ''
  }, CP:${postalCode}, ${city}, ${country}`;

export const useAdressSelector = (searchAddress, parameters) => {
  const [locationSuggestion, setLocationSuggestion] = useState([]);
  useEffect(() => {
    const getSuggestions = async () => {
      try {
        const geoCodeResponse = await axios.get(
          `${API.geocode.get}&${new URLSearchParams({
            ...parameters,
            query: searchAddress,
          })}`,
        );
        if (geoCodeResponse.status === 200) {
          const geoLocations = geoCodeResponse.data.suggestions.map(
            (location) => location.address,
          );
          setLocationSuggestion(geoLocations);
        }
      } catch (error) {
        // No actions on error situations
      }
    };
    if (searchAddress) {
      getSuggestions();
    }
  }, [searchAddress, parameters]);
  let locationList;
  if (locationSuggestion && locationSuggestion.length > 0) {
    locationList = locationSuggestion.map((location) => ({
      value: getFullAddress(location),
    }));
  }
  return {
    getFullAddress,
    setLocationSuggestion,
    locationSuggestion,
    locationList,
  };
};

export const getPrefix = (phone) => {
  if (!phone) {
    return null;
  }
  const regExp = PREFIX_REGEX;
  const prefix = phone.match(regExp);
  return prefix ? prefix[0] : null;
};

export const removePrefix = (phone) => {
  if (!phone) {
    return '';
  }
  const regExp = PREFIX_REGEX;
  const prefix = phone.match(regExp);
  if (prefix) {
    const phoneWithoutPrefix = phone.replace(prefix[0], '').trim();
    return phoneWithoutPrefix.replace(/[()]/g, '').trim();
  }
  return phone;
};

export function useBuildPackage(items) {
  const globals = useSelector(selectGlobals);
  const countries = useSelector(selectCountries);
  const result = items.map((packet) => {
    const prefix = getPrefix(packet.shippingScheduleDestination.phone);
    const countryID = countries.find((item) => item.dialingCode === prefix)?.id;
    return {
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationPersonName: packet.shippingScheduleDestination.name,
      shippingScheduleDestination: {
        ...packet.shippingScheduleDestination,
        phone: removePrefix(packet.shippingScheduleDestination.phone),
      },
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTermDescription: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      packageSizeDescription: globals.packages.size.find(
        (packSize) => packSize.value === packet.packageSize,
      ).name,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? dayjs(packet.arrivedAtPaqueryPointDate)
            .tz()
            .format(TableDateTimeFormat)
        : null,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
      prefix,
      countryID,
    };
  });
  return result;
}
