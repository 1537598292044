/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, notification } from 'antd';
import API from 'constants/api';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import rest from 'util/Api';

const downloadByRequest = async (url, filename) => {
  try {
    await rest.get(url);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  } catch (error) {
    notification.error({
      message: 'Atención',
      description:
        'No se puede descargar el archivo en este momento, itente mas tarde.',
    });
  }
};

const DownloadLabelButton = ({ record }) => {
  const handleClick = () => {
    if (record.successCount > 0) {
      downloadByRequest(
        API.packages.labelByMassive(record.code),
        `Etiqueta-${record.code}.pdf`,
      );
    } else {
      notification.info({
        message: 'Atención',
        description:
          'La alta masiva no se encuentra con paquetes creados correctamente. Por lo que no se imprime recibo',
      });
    }
  };

  return (
    <Tooltip title="Imprimir etiqueta">
      <Button type="primary" onClick={handleClick}>
        <VerticalAlignBottomOutlined />
      </Button>
    </Tooltip>
  );
};

DownloadLabelButton.propTypes = {
  record: PropTypes.shape({
    successCount: PropTypes.number,
    code: PropTypes.string,
  }).isRequired,
};

export default DownloadLabelButton;
