import React, { useEffect, useCallback, useState } from 'react';
import rest from 'util/Api';
import store from 'app/store';
import useUsers from 'redux/users';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  usersLoadedSelector,
  usersFilteredPagedItems,
  usersItemsSelector,
} from 'redux/users/selectors';
import { actions } from 'redux/users/slice';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import API from 'constants/api';
import { ExportDateFormat } from 'constants/dateFormats';
import CsvDownloader from 'util/CsvDownloader';
import { notification } from 'antd';
import dayjs from 'dayjs';
import DeleteModal from 'components/deleteModal';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';

const deleteAction = (userId, userName) => {
  const deleteRequest = async () => {
    let response;
    try {
      response = await rest.del(`${API.user.delete}${userId}`);
      if (response.status === 200) {
        store.dispatch({ type: actions.removeUser.type, payload: userId });
      }
    } catch (error) {
      // Nothing to do
    }
    return response;
  };
  DeleteModal({
    title: `¿Estas seguro de que deseas eliminar a ${userName}?`,
    onConfirmation: deleteRequest,
  });
};

const idColumn = {
  title: 'ID',
  dataIndex: 'id',
};

const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};

const lastNameColumn = {
  title: 'Apellido',
  dataIndex: 'lastName',
};

const mailColumn = {
  title: 'Usuario',
  dataIndex: 'mail',
};

const roleColumn = {
  title: 'Rol',
  dataIndex: 'role',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'status',
};

const editColumn = {
  align: 'center',
  render: (_, record) => <EditRecordButton link="/users" record={record} />,
};

const deleteColumn = {
  align: 'center',
  render: (_, record) => (
    <DeleteRecordButton
      onClick={() => {
        return deleteAction(record.key, `${record.name} ${record.lastName}`);
      }}
    />
  ),
};

const dataColumns = [
  idColumn,
  nameColumn,
  lastNameColumn,
  mailColumn,
  roleColumn,
  statusColumn,
];

const columnsLargeDevice = [...dataColumns, editColumn, deleteColumn];

const columnsSmallDevice = [idColumn, editColumn, deleteColumn];

const columnsMediumDevice = [idColumn, statusColumn, editColumn, deleteColumn];

const statusList = [
  { name: 'Activo', value: 1 },
  { name: 'Dado de baja', value: 6 },
];

const UserList = () => {
  useUsers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const { roles } = globals;
  const loaded = useSelector(usersLoadedSelector);
  const { items, pageable } = useSelector(usersFilteredPagedItems);
  const fullItems = useSelector(usersItemsSelector);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(actions.initial());
  }, [dispatch]);

  const getCsv = () => {
    setDisabled(true);
    try {
      const csv = fullItems;
      const csvHeader = [
        { label: 'ID', value: 'id' },
        { label: 'Nombre', value: 'name' },
        { label: 'Apellido', value: 'lastName' },
        { label: 'Email', value: 'email' },
        { label: 'Teléfono', value: 'mobile' },
        { label: 'País', value: 'countryName' },
        { label: 'Ciudad', value: 'cityName' },
        { label: 'Rol', value: 'roleName' },
        { label: 'Estado', value: 'statusName' },
      ];
      const filename = `usuarios-${dayjs().tz().format(ExportDateFormat)}.csv`;
      CsvDownloader(csv, csvHeader, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer los usuarios',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );
  const selectRoleCallback = useCallback(
    (value) => {
      dispatch(actions.updateRole(value));
    },
    [dispatch],
  );

  const selectCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );

  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePageable(page));
    },
    [dispatch],
  );

  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };
  const selectors = [
    {
      list: roles,
      placeholder: 'Rol',
      onChange: selectRoleCallback,
    },
    {
      onChange: selectCallback,
      placeholder: 'Estado',
      list: statusList,
    },
  ];

  let filteredItems = [];
  if (loaded && items) {
    filteredItems = items.map((user) => ({
      key: user.id,
      id: user.id,
      name: user.name,
      lastName: user.lastName,
      mail: user.email,
      role: user.roleName,
      status: user.statusName,
    }));
  }

  return (
    <>
      <PaqueryTable
        loading={!loaded}
        header={{
          title: 'Usuarios',
          selectors,
          searcher,
          primaryButton: <TableHeader.AddButton url="/users/add" />,
          onExportCsv: { callback: getCsv, disabled },
          refresh: () => dispatch(actions.refreshPage()),
        }}
        onChangePaginate={updatePaginate}
        dataSource={filteredItems}
        paginate={pageable || DEFAULT_PAGINATE}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

export default UserList;
