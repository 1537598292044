import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: null,
  loaded: false,
  avatar: {
    name: undefined,
    data: undefined,
  },
};

const userViewSlice = createSlice({
  name: 'userView',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      state.user = {
        ...action.payload,
        store: action.payload.ownerID,
        phone: action.payload.mobile,
      };
    },
    failure: (state) => {
      state.loaded = true;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    resetValues: () => initialState,
  },
});

export const { name, actions, reducer } = userViewSlice;
