import Api from 'util/Api';
import API from 'constants/api';

export const getPackage = async (id: string): Promise<any> => {
  const response = await Api.apiAxios.get(`${API.packages.getById}${id}`);
  const routes = response.data.data;

  return routes;
};

const PackageService = {
  getPackage,
};

export default PackageService;
