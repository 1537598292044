import React from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { theme } from 'antd';

const ViewPackageButton = ({ packet }) => {
  const { token } = theme.useToken();
  return (
    <Link to={`/packages/view/${packet.id}`}>
      <EyeOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
    </Link>
  );
};

ViewPackageButton.propTypes = {
  packet: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ViewPackageButton;
