import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const selectPackagesWithoutZone = (state) => state[name] || initialState;

export const packagesWithoutZoneItemsSelector = (state) => state[name].items;

export const packagesWithoutZonePaginationSelector = (state) =>
  state[name].pageable;

export const packagesWithoutZoneLoadedSelector = (state) => state[name].loaded;

export const packagesWithoutZoneDateSelector = (state) => state[name].date;

export const packagesWithoutZoneFetchedDatesSelector = (state) =>
  state[name].fetchedDates;

export const packagesWithoutZoneTypeSelector = (state) => state[name].type;

export const packagesWithoutZoneStatusSelector = (state) => state[name].status;

export const packagesWithoutZoneSearchSelector = (state) => state[name].search;

const makeSelectpackagesWithoutZone = () =>
  createSelector(selectPackagesWithoutZone, (substate) => substate);

export default makeSelectpackagesWithoutZone;
