import api from 'util/Api';
import axios from 'axios';
import { SHOP_OWNERTYPE } from 'constants/defaultValues';
import {
  ALLOWED_ROLES,
  STORE_ROLES,
  MARKETPLACE_ROLES,
  APP_ROLE_MARKETPLACE,
  APP_ROLE_STORE,
} from 'constants/roles';
import API from 'constants/api';
import { getCurrentPosition } from 'constants/operations';
import { saveKey } from 'util/localstorage';
import {
  put,
  call,
  all,
  takeLatest,
  select,
  take,
  fork,
  cancel,
  race,
} from 'redux-saga/effects';
import { actions as globalsActions } from 'redux/globals/slice';
import { logout, clearReducers } from 'redux/logout';
import { message } from 'antd';
import { authTokenSelector } from './selectors';
import { actions } from './slice';

function* getGeocodeParameters() {
  const parameters = {
    app_id: 'MBtttw0ilTLGyGoskMgB',
    app_code: 'e7fgY70B6UbgzM0DejwUSw',
    resultType: 'houseNumber',
    maxresults: '20',
    language: 'es',
  };
  try {
    if (navigator.geolocation) {
      const { coords } = yield call(getCurrentPosition);
      const latitude = parseFloat(coords.latitude).toFixed(6);
      const longitude = parseFloat(coords.longitude).toFixed(6);
      parameters.prox = `${latitude},${longitude}`;
    }
  } catch (error) {
    // do nothing
  }
  yield put({ type: actions.setGeocode.type, payload: parameters });
}

function* fetchMarketplaceInfo(profile) {
  if (!profile.marketplaceID) {
    throw new Error(
      'No tienes un marketplace asignado. Consulte con el administrador.',
    );
  }
  const requestMarketplace = yield call(
    // eslint-disable-next-line import/no-named-as-default-member
    api.get,
    `${API.marketplaces.get}/${profile.marketplaceID}`,
  );
  if (!api.isSuccessResponse(requestMarketplace)) {
    throw new Error('Error obteniendo informacion de tu marketplace.');
  }
  profile.marketplace = requestMarketplace.data.data;
  return profile;
}

function* fetchMarketplaceStoreInfo(profile) {
  if (
    MARKETPLACE_ROLES.some((marketRole) => marketRole === profile.userRoleID)
  ) {
    profile = yield call(fetchMarketplaceInfo, profile);
    profile.AppRole = APP_ROLE_MARKETPLACE;
    return profile;
  }
  if (STORE_ROLES.some((storeRole) => storeRole === profile.userRoleID)) {
    if (profile.ownerType !== SHOP_OWNERTYPE) {
      throw new Error(
        'El tipo de rol de tienda es incorrecto. Consulte con el administrador.',
      );
    }
    if (!profile.ownerID) {
      throw new Error(
        'No tenés una tienda asignada. Consulta con el administrador',
      );
    }
    const requestStore = yield call(
      api.apiAxios.get,
      `${API.stores.get}/${profile.ownerID}`,
    );
    if (!api.isSuccessResponse(requestStore)) {
      throw new Error('Error obteniendo informacion de tu tienda.');
    }
    profile.store = requestStore.data.data;
    profile.marketplaceID = profile.store.ownerId;
    profile.AppRole = APP_ROLE_STORE;
    profile = yield call(fetchMarketplaceInfo, profile);
    return profile;
  }
  throw new Error(
    'Existe un problema con el Rol asignado, consultar con administrador',
  );
}

function* fetchProfileSaga() {
  const profileResponse = yield call(api.apiAxios.get, API.profile.load);
  if (!api.isSuccessResponse(profileResponse)) {
    throw new Error(
      `Error al obtener el perfil del usuario${
        profileResponse.data.message ? `: ${profileResponse.data.message}` : '.'
      }`,
    );
  }
  const profile = profileResponse.data.data;
  if (
    !ALLOWED_ROLES.some((allowedRole) => allowedRole === profile.userRoleID)
  ) {
    throw new Error(
      'El usuario no es operador o administrador de marketplaces',
    );
  }
  const profileWithOwnerInfo = yield call(fetchMarketplaceStoreInfo, profile);

  return profileWithOwnerInfo;
}

function* fetchLoggedUserSaga() {
  const sessionToken = yield select(authTokenSelector);
  if (sessionToken) {
    api.apiAxios.defaults.headers.common.authorization = `bearer ${sessionToken}`;
    try {
      const profile = yield call(fetchProfileSaga);
      yield put({ type: actions.fetchProfileSuccess.type, payload: profile });
      yield fork(getGeocodeParameters);
    } catch (error) {
      yield put({ type: actions.fetchProfileFailure.type });
      message.error(error.message);
    }
  }
}

function* fetchLoginSaga(email, password) {
  const body = new URLSearchParams({
    username: email,
    password,
    grant_type: 'password',
  });
  const request = {
    url: API.auth.login,
    method: 'POST',
    data: body.toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  try {
    const response = yield call(axios, request);
    const token = response.data.access_token;
    api.apiAxios.defaults.headers.common.authorization = `bearer ${token}`;
    const profile = yield call(fetchProfileSaga);
    const payload = {
      profile,
      sessionToken: token,
    };
    saveKey('sessionToken', token);
    yield put({ type: actions.success.type, payload });
    yield fork(getGeocodeParameters);
  } catch (error) {
    yield put({ type: actions.failure.type });
    if (error && error.message.includes(400)) {
      message.error('Usuario o contraseña incorrectos.');
    } else {
      message.error(error.message);
    }
  }
}

function* watchLogin() {
  while (true) {
    const { credentials } = yield race({
      credentials: take(actions.fetch.type),
      rehydrate: take(actions.fetchLoggedUser.type),
    });
    let task;
    if (credentials) {
      const {
        payload: { email, password },
      } = credentials;
      task = yield fork(fetchLoginSaga, email, password);
    }
    yield take([
      logout.type,
      actions.failure.type,
      actions.fetchProfileFailure.type,
    ]);
    localStorage.removeItem('sessionToken');
    yield put({ type: clearReducers.type });
    if (task) {
      yield cancel(task);
    }
  }
}

function* reloadTimeZone() {
  yield put({ type: globalsActions.success.type });
}

export default function* rootSaga() {
  yield all([
    yield fork(watchLogin),
    yield takeLatest(
      [actions.fetchLoggedUser.type, actions.reloadProfile.type],
      fetchLoggedUserSaga,
    ),
    yield takeLatest(actions.reloadProfile, reloadTimeZone),
  ]);
}
