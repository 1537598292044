import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  items: [],
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  sort: '',
  search: '',
  loaded: true,
};

const packagesBySearchCode = createSlice({
  name: 'packagesBySearchCode',
  initialState,
  reducers: {
    fetchPackages: (state) => {
      state.loaded = false;
    },
    fetchPackagesSuccess: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    fetchPackagesFailure: (state) => {
      state.loaded = true;
    },
    updateSearchPackages: (state, action) => {
      state.loaded = false;
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    updateSortPackages: (state, action) => {
      state.loaded = false;
      state.sort = action.payload;
    },
    updatePaginationPackages: (state, action) => {
      state.loaded = false;
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    refreshPage: (state) => {
      state.loaded = false;
    },
    reset: () => initialState,
  },
});

export const { name, actions, reducer } = packagesBySearchCode;
