import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import rest, { getData } from 'util/Api';
import { notification } from 'antd';
import { selectGlobals } from 'redux/globals/selectors';
import { actions } from 'redux/userView/slice';
import { actions as actStore } from 'redux/store/slice';
import useUserView from 'redux/userView';
import useStore from 'redux/store';
import {
  userViewAvatar,
  userViewLoaded,
  userviewUserSelector,
} from 'redux/userView/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import { authProfile } from 'redux/auth/selectors';
import { IMAGE_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import * as Yup from 'yup';
import User from '../user';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del usuario'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del usuario',
  ),
  phone: Yup.number()
    .typeError('El teléfono tiene que ser un número y es requerido')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
});

const DEFAULT_COUNTRY = 1; // ARGENTINA
const DEFAULT_ROLE = 5; // Admin MP

const defaultValues = {
  id: null,
  name: '',
  lastName: '',
  phone: null,
  email: '',
  pwd: '',
  confirmpwd: '',
  countryID: DEFAULT_COUNTRY,
  userRoleID: DEFAULT_ROLE,
  store: null,
};

const disabledInputs = {
  email: true,
};

const UserModify = () => {
  useUserView();
  useStore();
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const loaded = useSelector(userViewLoaded);
  const globals = useSelector(selectGlobals);
  const avatar = useSelector(userViewAvatar);
  const stores = useSelector(storeItemsSelector);
  const user = useSelector(userviewUserSelector);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(actions.fetch(id));
    dispatch(actStore.fetch());
  }, [id, dispatch]);

  const selectCountry = async (country) => {
    dispatch(actions.setLoaded(false));
    const citiesResponse = await getData(`${API.globals.cities}/${country}`);
    setCities(citiesResponse);
    if (citiesResponse.length > 0) {
      setCurrentCity(citiesResponse[0].id);
    } else {
      setCurrentCity(null);
    }
    dispatch(actions.setLoaded(true));
  };

  useEffect(() => {
    const getUser = async () => {
      const citiesResponse = await getData(
        `${API.globals.cities}/${user.countryID}`,
      );
      dispatch(actions.setAvatar({ data: user.avatar }));
      setCities(citiesResponse);
      setCurrentCity(user.cityID);
      dispatch(actions.setLoaded(true));
    };
    if (user) {
      getUser();
    }
  }, [user, history, dispatch]);

  const handleSubmit = async (values) => {
    dispatch(actions.setLoaded(false));
    const payload = {
      id: parseInt(id, 10),
      AuthMode: 1,
      Name: values.name,
      LastName: values.lastName,
      Email: values.email,
      StatusID: values.statusID,
      UserRoleID: values.userRoleID,
      CountryID: values.countryID,
      CityID: values.cityID,
      Pwd: values.pwd,
      Mobile: parseInt(values.phone, 10),
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      LogisticOperatorID: values.LogisticOperatorID || null,
      marketplaceID: profile.marketplace.id,
      storeID: values.ownerID || null,
      TermsAndConditions: false,
      paqueryPointID: null,
    };
    try {
      const response = await rest.post(API.user.update, payload);
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Actualización de usuario',
          description: `El usuario ${values.name} ${values.lastName} ha sido actualizado correctamente.`,
        });
        setTimeout(() => {
          history.push('/users');
        }, 1000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(actions.setLoaded(true));
      // Nothing to do with error
    }
  };
  return (
    <User
      disabledInputs={disabledInputs}
      loaded={loaded}
      avatar={avatar}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={user || defaultValues}
      stores={stores}
      countries={globals.countries}
      cities={cities}
      roles={globals.roles}
      currentCity={currentCity}
      setCurrentCountry={selectCountry}
      isUserModify
    />
  );
};

UserModify.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default UserModify;
