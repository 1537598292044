import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { TableDateTimeFormat } from 'constants/dateFormats';
import dayjs from 'dayjs';
import ModalButton from 'components/modalButton';
import ViewPackageButton from 'components/viewPackageButton';
import { useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  itemsSelector,
  pageableSelector,
} from 'redux/packagesByStoreHistory/selectors';
import { PACKAGE } from 'constants/packages';

const statusStyle = (status) => {
  let bgColor;
  switch (status) {
    case PACKAGE.STATUSES.DELIVERED:
      bgColor = 'green';
      break;
    case PACKAGE.STATUSES.CANCELLED:
      bgColor = 'red';
      break;
    case PACKAGE.STATUSES.DAMAGED:
      bgColor = 'orange';
      break;
    case PACKAGE.STATUSES.RETURNED:
      bgColor = 'gold';
      break;
    default:
      break;
  }
  return {
    background: bgColor,
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
};

const statusColumn = {
  title: 'Estado',
  render: (_, record) => (
    <span style={statusStyle(record.status)}>{record.statusDescription}</span>
  ),
};

const driverAvatar = (record) => (
  <ModalButton
    name={record.driverName}
    image={record.driverImage}
    icon={<UserOutlined />}
  />
);

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const driverColumn = {
  title: 'PaQuer',
  align: 'center',
  render: (_, record) => driverAvatar(record),
};

const driverNameColumn = {
  title: 'Conductor',
  dataIndex: 'driverName',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packet={record} />,
};

const columns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  driverNameColumn,
  statusColumn,
];

const colsLG = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  driverColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  statusColumn,
  viewPackageColumn,
];

const colsSM = [externalCodeColumn];

const colsMD = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
];

const useBuildHistoryPackages = (sourcePackages) => {
  const [packages, setPackages] = useState([]);
  const globals = useSelector(selectGlobals);
  useEffect(() => {
    if (sourcePackages) {
      setPackages(
        sourcePackages.map((packet) => ({
          ...packet,
          key: packet.id,
          externalCode: packet.externalCode,
          destinationAddress:
            packet.shippingScheduleDestination.shippingAddress.addressDetail,
          deliveryTerm: globals.deliveryTerm.find(
            (term) => term.value === packet.deliveryTerm,
          ).description,
          content: packet.caption,
          driverImage: packet.shippingScheduleDestination.driver
            ? packet.shippingScheduleDestination.driver.avatar
            : null,
          driverName: packet.shippingScheduleDestination.driver
            ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName}`
            : null,
          deliveryDate: packet.deliveryDate
            ? dayjs(packet.deliveryDate).tz().format(TableDateTimeFormat)
            : null,
          arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
            ? dayjs(packet.arrivedAtPaqueryPointDate)
                .tz()
                .format(TableDateTimeFormat)
            : null,
          signatureImage: packet.signatureImage,
          signatureName: packet.shippingScheduleDestination.name,
          statusDescription: globals.packages.status.find(
            (state) => state.value === packet.status,
          ).name,
        })),
      );
    }
  }, [sourcePackages, globals]);
  return packages;
};

export default function useTableDataHistory() {
  const items = useSelector(itemsSelector);
  const pageable = useSelector(pageableSelector);
  const packages = useBuildHistoryPackages(items);
  return {
    packages,
    pageable,
    colsSM,
    colsMD,
    colsLG,
    columns,
  };
}
