import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import rest, { getData } from 'util/Api';
import { notification } from 'antd';
import { selectGlobals } from 'redux/globals/selectors';
import { actions } from 'redux/userView/slice';
import useUserView from 'redux/userView';
import useStore from 'redux/store';
import { actions as storeActions } from 'redux/store/slice';
import { userViewAvatar, userViewLoaded } from 'redux/userView/selectors';
import { storeItemsSelector } from 'redux/store/selectors';
import { authProfile } from 'redux/auth/selectors';
import { IMAGE_REGEX } from 'constants/defaultValues';
import { STORE_ROLES } from 'constants/roles';
import API from 'constants/api';
import * as Yup from 'yup';
import User from '../user';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del usuario'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del usuario',
  ),
  phone: Yup.number()
    .typeError('El teléfono tiene que ser un número y es requerido')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
});

const DEFAULT_COUNTRY = 1; // ARGENTINA
const DEFAULT_ROLE = 5; // Admin MP

const defaultValues = {
  name: '',
  lastName: '',
  phone: null,
  email: '',
  pwd: '',
  confirmpwd: '',
  countryID: DEFAULT_COUNTRY,
  userRoleID: DEFAULT_ROLE,
  store: null,
};

const UserAdd = () => {
  useUserView();
  useStore();
  const dispatch = useDispatch();
  const profile = useSelector(authProfile);
  const loaded = useSelector(userViewLoaded);
  const globals = useSelector(selectGlobals);
  const avatar = useSelector(userViewAvatar);
  const stores = useSelector(storeItemsSelector);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();

  const selectCountry = useCallback(
    async (country) => {
      dispatch(actions.setLoaded(false));
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.setLoaded(true));
    },
    [dispatch],
  );

  useEffect(() => {
    if (globals.cities.length > 0) {
      dispatch(actions.resetValues());
      selectCountry(DEFAULT_COUNTRY);
    }
  }, [dispatch, globals.cities, selectCountry]);
  useEffect(() => {
    dispatch(storeActions.fetch());
  }, [dispatch]);

  const handleSubmit = async (values) => {
    dispatch(actions.setLoaded(false));
    const payload = {
      AuthMode: 1,
      Name: values.name,
      LastName: values.lastName,
      Email: values.email,
      UserRoleID: values.userRoleID,
      CountryID: values.countryID,
      CityID: values.cityID,
      Pwd: values.pwd,
      Mobile: values.phone,
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      LogisticOperatorID: profile.logisticOperatorID || null,
      marketplaceID: profile.marketplace.id,
      storeID: STORE_ROLES.some((storeRole) => storeRole === values.userRoleID)
        ? values.ownerID
        : null,
      TermsAndConditions: false,
      paqueryPointID: null,
    };
    try {
      const response = await rest.post(API.user.create, payload);
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Creación de usuario',
          description: `El usuario ${values.name} ${values.lastName} ha sido creado correctamente.`,
        });
        setTimeout(() => {
          history.push('/users');
        }, 1000);
      }
    } catch (error) {
      dispatch(actions.setLoaded(true));
      // Nothing to do with error
    }
  };
  return (
    <User
      loaded={loaded}
      avatar={avatar}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={defaultValues}
      stores={stores}
      countries={globals.countries}
      cities={cities}
      roles={globals.roles}
      currentCity={currentCity}
      setCurrentCountry={selectCountry}
      isAdd
    />
  );
};

UserAdd.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default UserAdd;
