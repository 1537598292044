import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const globalsNotifierTypeSelector = (state) => state[name].notifierType;

export const selectGlobals = (state) => state[name] || initialState;
export const selectCountries = (state) => state[name].countries;

export const packagesStatusesGlobalsSelector = (state) =>
  state[name].packages.status;

const makeSelectGlobals = () =>
  createSelector(selectGlobals, (substate) => substate);

export const packagesTypesGlobalsSelector = (state) =>
  state[name].packages.type;

export default makeSelectGlobals;
