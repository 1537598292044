import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Spin, theme, Tooltip } from 'antd';
import useStyles from 'components/Forms/styles';
import InputField from 'components/Forms/input';
import CheckboxField from 'components/Forms/checkbox';
import { globalsNotifierTypeSelector } from 'redux/globals/selectors';
import { storeLoadedSelector } from 'redux/store/selectors';
import { SelectField } from 'components/Forms';
import { OTHER_NOTIFIER_OPTION } from 'constants/options';
import Card from 'components/card';
import { InfoCircleOutlined } from '@ant-design/icons';

const Integration = ({ disabledIntegration }) => {
  const notifierTypes = useSelector(globalsNotifierTypeSelector);
  const { values } = useFormikContext();

  const optionsNotifierType = [
    OTHER_NOTIFIER_OPTION,
    ...notifierTypes.map((notifierType) => ({
      label: notifierType.description,
      value: notifierType.value,
    })),
  ];

  return (
    <>
      <Col xs={24} sm={24} style={{ display: 'flex', justifyContent: 'start' }}>
        <CheckboxField
          label="Integracion con canal de venta"
          fieldName="hasIntegration"
          disabled={disabledIntegration}
        />
      </Col>
      {values?.hasIntegration && (
        <>
          <Col span={24}>
            <SelectField
              label="Tipo de integración"
              fieldName="notifierType"
              options={optionsNotifierType}
              disabled={disabledIntegration}
            />
          </Col>
          <Col span={24}>
            <InputField
              type="string"
              label="Usuario"
              fieldName="username"
              disabled={disabledIntegration}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              label="Contraseña"
              fieldName="password"
              type="password"
              disabled={disabledIntegration}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              label="Confirmar Contraseña"
              fieldName="confirmpassword"
              type="password"
              disabled={disabledIntegration}
            />
          </Col>
        </>
      )}
    </>
  );
};

const Store = ({
  validationSchema,
  onSubmit,
  initialValues,
  disabledIntegration,
}) => {
  const formik = useRef(null);
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const loaded = useSelector(storeLoadedSelector);

  return (
    <Spin spinning={!loaded}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        <Form>
          <Card title="Datos de la Tienda" style={{ margin: '8px' }}>
            <Row>
              <Col span={24} className={classes.cardContent}>
                <Row justify="start" align="middle" gutter={22}>
                  <Col xs={24} sm={12}>
                    <InputField type="string" label="Nombre" fieldName="name" />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField
                      type="string"
                      label="Código de tienda"
                      fieldName="storeCode"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField
                      type="string"
                      label="Dirección de despacho"
                      fieldName="address"
                      suffix={
                        <Tooltip title="Si se deja vacío se usará la dirección del marketplace">
                          <InfoCircleOutlined style={{ color: 'gray' }} />
                        </Tooltip>
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField
                      type="string"
                      label="Nombre de contacto"
                      fieldName="contactName"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField label="Número de teléfono" fieldName="phone" />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField
                      type="string"
                      label="Detalle"
                      fieldName="detail"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <InputField type="string" label="Email" fieldName="email" />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    style={{ display: 'flex', justifyContent: 'start' }}
                  >
                    <CheckboxField label="Activa" fieldName="active" />
                  </Col>
                  <Integration disabledIntegration={disabledIntegration} />
                </Row>
              </Col>
            </Row>
          </Card>
          <Row type="flex" justify="end">
            <Button
              loading={!loaded}
              type="primary"
              htmlType="submit"
              className={classes.saveButton}
              size="large"
            >
              Guardar
            </Button>
          </Row>
        </Form>
      </Formik>
    </Spin>
  );
};
Store.propTypes = {
  validationSchema: PropTypes.shape({}),
  disabledIntegration: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    detail: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    contactName: PropTypes.string,
    email: PropTypes.string,
    active: PropTypes.bool,
    storeCode: PropTypes.string,
  }),
};

Store.defaultProps = {
  disabledIntegration: false,
  validationSchema: null,
  initialValues: {
    name: '',
    id: '',
    detail: '',
    address: '',
    phone: '',
    contactName: '',
    email: '',
    active: true,
    storeCode: '',
  },
};

Integration.propTypes = {
  disabledIntegration: PropTypes.bool,
};

Integration.defaultProps = {
  disabledIntegration: false,
};

export default Store;
