const SITELINKS = {
  dashboard: '/dashboard',
  profiles: '/profile',
  users: {
    list: '/users',
    add: '/users/add',
    edit: '/users/:id',
  },
  stores: {
    list: '/stores',
    add: '/stores/add',
    edit: '/stores/:storeId',
  },
  packages: {
    bySearchCode: '/packagesBySearchCode',
    list: '/packages',
    history: '/packagesHistory',
    withoutZone: '/packages/withoutZones',
    massive: '/packageMassiveAdd',
    edit: '/packages/:externalCode',
    editById: '/packages/id/:id',
    send: '/packages/send',
    view: '/packages/view/:id',
  },
  storePackages: {
    list: '/storePackages',
    history: '/storePackageHistory',
    massive: '/storePackageMassiveAdd',
  },
  support: {
    index: '/support',
  },
  error: '/error',
};

export default SITELINKS;
