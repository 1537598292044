import { createSlice } from '@reduxjs/toolkit';
import {
  ALL_ROLES_FILTERING,
  ALL_STATUS_FILTERING,
} from 'constants/defaultValues';
import { clearReducers } from '../logout';

export const initialState = {
  items: {},
  loaded: false,
  pageable: {
    pageNumber: 0,
    pageSize: 10,
  },
  search: '',
  role: '',
  status: '',
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    fetchSuccess: (state, action) => {
      action.payload.items.forEach((user) => {
        state.items[user.id] = user;
      });
      state.role = action.payload.role;
      state.status = action.payload.status;
      state.loaded = true;
    },
    fetchFailure: (state) => {
      state.loaded = true;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    updateRole: (state, action) => {
      state.pageable.pageNumber = 0;
      state.role = action.payload ? action.payload : ALL_ROLES_FILTERING;
    },
    updateStatus: (state, action) => {
      state.pageable.pageNumber = 0;
      state.status = action.payload ? action.payload : ALL_STATUS_FILTERING;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    removeUser: (state, action) => {
      delete state.items[action.payload];
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, actions, reducer } = usersSlice;
