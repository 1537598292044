import { useSelector, shallowEqual } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { name as key, reducer } from './slice';
import saga from './saga';
import makeSelectStoreModifyHistory from './selectors';

export default function useStoreHistoryPackages() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useSelector(makeSelectStoreModifyHistory(), shallowEqual);
}
