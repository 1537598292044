import React, { useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import API from 'constants/api';
import rest from 'util/Api';

const { Title } = Typography;

const ChangePassword = ({ email }) => {
  const [visible, setVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOnChangePassword = async () => {
    // const URL = isUser ? API.user.changeUserPassword : API.user.changePassword;
    setVisible(false);
    setLoading(true);
    try {
      const body = {
        email,
      };
      const bodyParsed = new URLSearchParams(body);
      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      await rest.post(
        `${API.user.changePassword}`,
        bodyParsed.toString(),
        options,
      );
      setModalMessage(
        'Enviamos un email con los pasos para cambiar la contraseña.',
      );
      setVisible(true);
    } catch (error) {
      setModalMessage(
        `Hubo un error al solicitar el cambio de contraseña, ${error.message}`,
      );
      setVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        loading={loading}
        type="primary"
        style={{ width: '100%' }}
        onClick={() => handleOnChangePassword()}
      >
        Cambiar contraseña
      </Button>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={
          <Button type="primary" onClick={() => setVisible(false)}>
            OK
          </Button>
        }
      >
        <Title level={5}>{modalMessage}</Title>
      </Modal>
    </>
  );
};

ChangePassword.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ChangePassword;
