import { createSelector } from 'reselect';
import { name, initialState } from './slice';

const storeSelector = (state) => state[name] || initialState;

export const storeItemsSelector = (state) => Object.values(state[name].items);

export const storeLoadedSelector = (state) => state[name].loaded;

export const storeSearchSelector = (state) => state[name].search;

export const storePageableSelector = (state) => state[name].pageable;

const makeStoreSelector = () =>
  createSelector(storeSelector, (substate) => substate);

export default makeStoreSelector;

export const storeItemsFiltered = createSelector(
  storeItemsSelector,
  storePageableSelector,
  storeSearchSelector,
  (items, { pageSize, pageNumber }, search) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const storeNameIgnoreCase = item.name.toUpperCase();
        return storeNameIgnoreCase.includes(searchIgnoreCase);
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);
