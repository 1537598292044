import { all, select, put, takeLatest, call } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { haveDateLoaded } from 'constants/operations';
import API from 'constants/api';
import { actions } from './slice';
import { selectMassiveViewPackages } from './selectors';

const URL = API.packages.getByMassiveCode;
function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize },
      code,
      search,
      status,
      type,
      date,
    } = yield select(selectMassiveViewPackages);
    if (!code) return;
    const searchParams = new URLSearchParams({
      code,
      page: pageNumber,
      size: pageSize,
      packageType: type || '',
      search,
      status,
      from: date.from,
      to: date.to,
    });
    const packages = yield call(getData, `${URL}?${searchParams.toString()}`);
    const fetchPayload = {
      items: packages.content,
      total: packages.totalElements,
    };
    yield put({ type: actions.success.type, payload: fetchPayload });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* catchUndefinedCode() {
  const { code, date } = yield select(selectMassiveViewPackages);
  if (!code || !haveDateLoaded(date)) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateDate,
        actions.updatePackageType,
        actions.updateSearch,
        actions.updateStatus,
        actions.updateCode,
        actions.refreshPage,
        actions.resetFilters,
      ],
      catchUndefinedCode,
    ),
    takeLatest(actions.fetch, fetchSaga),
  ]);
}
